// Here you can add other styles
.btn-primary {
  color: #fff;
  background-color: #95b72c;
  border-color: #0e750e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0e750e;
  border-color: #0e750e;
}

a {
  color: #8f7a2e;
}

a:hover {
  color: #aa933f;
  text-decoration: underline;
}

.sidebar .nav-link.active .nav-icon {
  color: #aa933f;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #baa24c;
  /*background: #0e750e;*/
}

.sidebar .nav-link:hover .nav-icon {
  color: #fff;
}

.bg-primary {
  background-color: #baa24c !important;
}

.bg-iso-gestion {
  background-image: url('./backgrounds/iso-gestion.jpg');
  background-size: cover;
}

.card.bg-primary {
  border-color: #baa24c;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #95b72c;
  border-color: #95b72c;
}
